@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

html,
body {
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

.page {
    min-height: 100vh;
}

.btn {
    border-radius: 2rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    height: 100%;
}
