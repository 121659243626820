.shift-item {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;
    min-height: 70px;
    
    padding: 10px;
    margin: 15px 0;
    border-radius: 10px;
    background: linear-gradient(270deg, rgb(78, 224, 200), rgb(50, 125, 224));
    color: #fff;

    &__header {
        span {
            font-weight: 400;
        }
    }

    &__action {
        font-size: 20px;
    }
}

.shift-detail {
    &__title {
        font-weight: 700;
    }
}

.day-schedule-item {
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    background: linear-gradient(270deg, rgb(166, 53, 211), rgb(69, 71, 192));
    color: #fff;
    min-height: 30px;

    h6  {
        margin-bottom: 0;

        > span {
            font-weight: 400;
        }
    }

    
}

.date-shift-item {
    padding: 10px;
    margin: 15px 0;
    border-radius: 10px;
    background: linear-gradient(270deg, rgb(224, 212, 50), rgb(224, 136, 78));
    color: #fff;
    min-height: 70px;

    &:last-child {
        margin-bottom: 20px;
    }
}
