.schedules {
    padding: 15px;

    &__item {
        display: grid;
        grid-template-columns: 1fr 50px;
        grid-template-rows: auto auto;
        grid-template-areas:
            "meta collapse"
            "details details";

        padding: 10px;
        margin: 15px 0;
        border-radius: 10px;
        background: linear-gradient(45deg, rgb(93, 78, 224), rgb(204, 50, 224));
        color: #fff;

        &__meta {
            grid-area: meta;
        }

        &__collapse {
            grid-area: collapse;
            color: #fff;
            font-size: 1.5rem;
            transition: all 0.3s ease-out;
            transform-origin: 50% 50%;

            &:hover,
            &:focus {
                color: #ddd;
            }
        }

        &__details {
            grid-area: details;
            max-height: 0px;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;

            &__select {
                color: #333;

                > div:first-child {
                    border-radius: 2rem;
                }
            }

            .btn {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                //color: rgb(93, 78, 224);

                > svg {
                    position: relative;
                    bottom: 2px;
                    margin-right: 10px;
                }
            }
        }

        &.open {
            .schedules__item__collapse {
                transform: rotate(180deg);
            }

            .schedules__item__details {
                max-height: 200px;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__main {
        h4 > small {
            font-size: 0.6em;
            display: inline-block;
        }
    }
}
