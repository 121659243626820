.page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(150px, auto) auto;
    grid-template-areas:
        "header"
        "main";

    @media (min-width: 768px) {
        padding: 0 50px;
    }
}

.header {
    grid-area: header;
    align-self: flex-end;
}

.main {
    grid-area: main;
}


