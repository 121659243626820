.dummy-item {
    @mixin niceLoader($width, $height) {
        background: repeating-linear-gradient(to right, rgba(255,255,255, 0.5) 0%, rgba(255,255,255, 0.8) 50%, rgba(255,255,255, 0.5) 100%);
        width: $width;
        height: $height;
        background-size: 200% auto;
        background-position: 0 100%;
        animation: gradient-loader 1.5s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    @keyframes gradient-loader {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

    &__title-loader {
        @include niceLoader(60%, 16px);
    }

    &__sub-loader {
        @include niceLoader(50%, 12px);
    }
}