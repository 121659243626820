@mixin shadow($color: rgba(0, 0, 0, 0.35)) {
    box-shadow: 5px 5px 39px -9px $color;
}

/*
.login {
    height: 100%;
    background: linear-gradient(to right, rgb(26, 215, 221), rgb(139, 44, 228));
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        border-radius: 15px;
        width: 300px;

        h1 {
            margin-bottom: 1em;
        }
        
        input {
            border-radius: 20px;

            &:focus {
                @include shadow();
            }
        }

        button {
            color: white;
            background: linear-gradient(to right, rgb(61, 236, 204), rgb(25, 157, 220));
            border: none;

            &:hover {
                color: white;
                @include shadow();
            }

            &:disabled {
                box-shadow: none;
            }
        }
    }
}

*/

.login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto;
    grid-template-areas: 
        "main main";
    height: 100vh;

    &__main {
        grid-area: main;
        padding: 0 30px;
        align-self: center;
    }

    &__void {
        grid-area: void;
        background: url("/img/segway2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__logo {
        margin: 0 auto;
        margin-bottom: 50px;
        display: block;
        width: 50%;
    }

    @media (min-width: 768px) {
        grid-template-areas:
            "main void"
            "main void";

        &__main {
            padding: 0 100px;
        }
    }
}
