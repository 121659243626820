@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
html,
body {
  height: 100%;
  box-sizing: border-box;
  font-size: 16px; }

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

.page {
  min-height: 100vh; }

.btn {
  border-radius: 2rem; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

#root {
  height: 100%; }

/*
.login {
    height: 100%;
    background: linear-gradient(to right, rgb(26, 215, 221), rgb(139, 44, 228));
    display: flex;
    justify-content: center;
    align-items: center;

    &__card {
        border-radius: 15px;
        width: 300px;

        h1 {
            margin-bottom: 1em;
        }
        
        input {
            border-radius: 20px;

            &:focus {
                @include shadow();
            }
        }

        button {
            color: white;
            background: linear-gradient(to right, rgb(61, 236, 204), rgb(25, 157, 220));
            border: none;

            &:hover {
                color: white;
                @include shadow();
            }

            &:disabled {
                box-shadow: none;
            }
        }
    }
}

*/
.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto auto;
  grid-template-areas: "main main";
  height: 100vh; }
  .login__main {
    grid-area: main;
    padding: 0 30px;
    align-self: center; }
  .login__void {
    grid-area: void;
    background: url("/img/segway2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .login__logo {
    margin: 0 auto;
    margin-bottom: 50px;
    display: block;
    width: 50%; }
  @media (min-width: 768px) {
    .login {
      grid-template-areas: "main void" "main void"; }
      .login__main {
        padding: 0 100px; } }

.schedules {
  padding: 15px; }
  .schedules__item {
    display: grid;
    grid-template-columns: 1fr 50px;
    grid-template-rows: auto auto;
    grid-template-areas: "meta collapse" "details details";
    padding: 10px;
    margin: 15px 0;
    border-radius: 10px;
    background: -webkit-linear-gradient(45deg, #5d4ee0, #cc32e0);
    background: linear-gradient(45deg, #5d4ee0, #cc32e0);
    color: #fff; }
    .schedules__item__meta {
      grid-area: meta; }
    .schedules__item__collapse {
      grid-area: collapse;
      color: #fff;
      font-size: 1.5rem;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }
      .schedules__item__collapse:hover, .schedules__item__collapse:focus {
        color: #ddd; }
    .schedules__item__details {
      grid-area: details;
      max-height: 0px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden; }
      .schedules__item__details__select {
        color: #333; }
        .schedules__item__details__select > div:first-child {
          border-radius: 2rem; }
      .schedules__item__details .btn {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700; }
        .schedules__item__details .btn > svg {
          position: relative;
          bottom: 2px;
          margin-right: 10px; }
    .schedules__item.open .schedules__item__collapse {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .schedules__item.open .schedules__item__details {
      max-height: 200px;
      opacity: 1;
      visibility: visible; }
  .schedules__main h4 > small {
    font-size: 0.6em;
    display: inline-block; }

@-webkit-keyframes gradient-loader {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -200% 0; } }

@keyframes gradient-loader {
  0% {
    background-position: 0 0; }
  100% {
    background-position: -200% 0; } }

.dummy-item__title-loader {
  background: -webkit-repeating-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 100%);
  background: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 100%);
  width: 60%;
  height: 16px;
  background-size: 200% auto;
  background-position: 0 100%;
  -webkit-animation: gradient-loader 1.5s infinite;
          animation: gradient-loader 1.5s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  margin-bottom: 10px;
  border-radius: 5px; }

.dummy-item__sub-loader {
  background: -webkit-repeating-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 100%);
  background: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 100%);
  width: 50%;
  height: 12px;
  background-size: 200% auto;
  background-position: 0 100%;
  -webkit-animation: gradient-loader 1.5s infinite;
          animation: gradient-loader 1.5s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  margin-bottom: 10px;
  border-radius: 5px; }

@charset "UTF-8";
.shift-item {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  min-height: 70px;
  padding: 10px;
  margin: 15px 0;
  border-radius: 10px;
  background: -webkit-gradient(linear, right top, left top, from(#4ee0c8), to(#327de0));
  background: -webkit-linear-gradient(right, #4ee0c8, #327de0);
  background: linear-gradient(270deg, #4ee0c8, #327de0);
  color: #fff; }
  .shift-item__header span  {
    font-weight: 400; }
  .shift-item__action  {
    font-size: 20px; }

.shift-detail__title {
  font-weight: 700; }

.day-schedule-item {
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background: -webkit-gradient(linear, right top, left top, from(#a635d3), to(#4547c0));
  background: -webkit-linear-gradient(right, #a635d3, #4547c0);
  background: linear-gradient(270deg, #a635d3, #4547c0);
  color: #fff;
  min-height: 30px; }
  .day-schedule-item h6 {
    margin-bottom: 0; }
    .day-schedule-item h6 > span {
      font-weight: 400; }

.date-shift-item {
  padding: 10px;
  margin: 15px 0;
  border-radius: 10px;
  background: -webkit-gradient(linear, right top, left top, from(#e0d432), to(#e0884e));
  background: -webkit-linear-gradient(right, #e0d432, #e0884e);
  background: linear-gradient(270deg, #e0d432, #e0884e);
  color: #fff;
  min-height: 70px; }
  .date-shift-item:last-child {
    margin-bottom: 20px; }

.shift-item__header > span {
    font-weight: 400;
}
.page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(150px, auto) auto;
  grid-template-areas: "header" "main"; }
  @media (min-width: 768px) {
    .page {
      padding: 0 50px; } }

.header {
  grid-area: header;
  align-self: flex-end; }

.main {
  grid-area: main; }

